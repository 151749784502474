import { useCallback } from "react";

import toast from "utils/toast";
import { getViewApiFieldsFromPageView } from "utils/apiUtils";

import { TableColumnType, TableViewConfig } from "types/baTypes";

import { ERROR_TYPES, TOAST_TYPE } from "utils/constants";
import useAddRecord from "./useAddRecord";
import useRemoveRecord from "./useRemoveRecord";
import useUpdateRecord from "./useUpdateRecord";
import useDeleteRecord from "./useDeleteRecord";
import useErrorLogger from "./useErrorLogger";

export type SaveViewInput = {
  newViewConfig: Partial<TableViewConfig>;
  columnIds: Array<{ columnId: string; pageColumnId?: string }>;
  onSuccess?: () => void;
};

export type UpdateViewInput = {
  viewId: string;
  newViewConfig: Partial<TableViewConfig>;
};

type AdditionalProps = {
  onSuccess: () => void;
};

// Manages update/delete for `ui_views` that are independant and not linked to pages
const useUpsertDeleteView = () => {
  const { updateRecordAsync } = useUpdateRecord();
  const { removeRecordAsync } = useRemoveRecord();
  const { addRecordAsync } = useAddRecord();
  const { deleteRecordAsync } = useDeleteRecord();
  const { logError } = useErrorLogger();

  const handleAddNewView = useCallback(
    async (input: SaveViewInput) => {
      const updatedViewInput = getViewApiFieldsFromPageView(input.newViewConfig);
      // First create a new view in `ui_views`
      const { data: newViewData } = await addRecordAsync({
        tableName: "ui_views",
        input: updatedViewInput
      });
      if (newViewData?.[0]?.id) {
        // Add columns to this view if any
        if (input.columnIds?.length) {
          const columnInputs = input.columnIds.map((colInput) => ({
            views_id: newViewData?.[0]?.id,
            columns_id: colInput.columnId,
            pages_columns_id: colInput.pageColumnId
          }));
          await addRecordAsync({
            tableName: "ui_views_columns",
            input: columnInputs
          });
          toast.success(`New ${input.newViewConfig.viewType} view created with columns linked!`);
          input.onSuccess?.();
        } else {
          toast.success(`New ${input.newViewConfig.viewType} view created!`);
          input.onSuccess?.();
        }
      } else {
        toast.error(`Error creating new ${input.newViewConfig.viewType} view!`);
      }
    },
    [addRecordAsync]
  );

  const handleUpdateView = useCallback(
    async ({ viewId, newViewConfig }: UpdateViewInput) => {
      const updatedViewInput = getViewApiFieldsFromPageView(newViewConfig);
      await updateRecordAsync({
        tableName: "ui_views",
        input: { ...updatedViewInput, id: viewId }
      });
    },
    [updateRecordAsync]
  );

  const handleAddColumnToView = useCallback(
    async (viewId: string, col: TableColumnType) => {
      await addRecordAsync({
        tableName: "ui_views_columns",
        input: {
          views_id: viewId,
          columns_id: col?.id,
          pages_columns_id: col?.pageColumnId
        }
      });
    },
    [addRecordAsync]
  );

  const handleRemoveViewColumn = useCallback(
    async (viewId: string, columnId: string) => {
      await removeRecordAsync({
        tableName: "ui_views_columns",
        compositePrimaryKey: { views_id: viewId, columns_id: columnId }
      });
    },
    [removeRecordAsync]
  );

  const handleDeleteView = useCallback(
    async (viewId?: string, additionalProps?: AdditionalProps) => {
      if (!viewId) return;
      const toastId = toast.success("Deleting record...", { autoClose: false });
      try {
        const { error } = await deleteRecordAsync({ tableName: "ui_views", id: viewId });
        if (error) {
          toast.update(toastId, "Error deleting record", { type: TOAST_TYPE.ERROR, autoClose: 2000 });
          return;
        }
        toast.update(toastId, "Record deleted successfully", { type: TOAST_TYPE.SUCCESS, autoClose: 2000 });
      } catch (err: any) {
        logError({
          error: err,
          source: "useUpsertDeleteView - handleDeleteView",
          type: ERROR_TYPES.HOOKS,
          message: err.message || "Error deleting records",
          url: window.location.href,
          additionalInfo: {
            viewId,
            additionalProps
          }
        });
        toast.update(toastId, "Error deleting record", { type: TOAST_TYPE.ERROR, autoClose: 2000 });
      }
      additionalProps?.onSuccess?.();
    },
    [deleteRecordAsync, logError]
  );

  return {
    handleAddNewView,
    handleRemoveViewColumn,
    handleAddColumnToView,
    handleUpdateView,
    handleDeleteView
  };
};

export default useUpsertDeleteView;
