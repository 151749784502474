import { useCallback, useState, useMemo } from "react";

import Select from "components/Select";
import Button from "components/Button";

import { ButtonAppearance, VIEWOPTION_OPTIONS, ViewOption } from "utils/constants";

import { SelectOption, SelectValue } from "types/common";

import useUpsertDeletePageViewConfig from "hooks/useUpsertDeletePageViewConfig";
import usePageConfigState from "hooks/usePageConfigState";
import usePageDataById from "hooks/usePageDataById";

const AddNewViewToPage = ({ pageId, onSuccess }: { pageId: string; onSuccess: () => void }) => {
  const [selectedView, setSelectedView] = useState<ViewOption>();
  const { handleAddNewPageView } = useUpsertDeletePageViewConfig();
  const { updatePageConfigModalState } = usePageConfigState();
  const { data: pageData } = usePageDataById(pageId);

  const finalViewOptions = useMemo(() => {
    const existingViews = pageData?.views?.map((view) => view.viewType);
    return VIEWOPTION_OPTIONS.filter((view) => !existingViews?.includes(view.title));
  }, [pageData?.views]);

  const handlePageViewSave = useCallback(
    async (selectedView: ViewOption) => {
      await handleAddNewPageView({
        viewType: selectedView,
        pageId,
        onSuccess
      });
      updatePageConfigModalState(null);
    },
    [handleAddNewPageView, pageId, onSuccess, updatePageConfigModalState]
  );

  if (pageData?.id && !finalViewOptions.length) {
    return (
      <div className="align-center mb-5 flex flex-row items-center ">
        <h3 className="text-primary mr-3 text-lg font-medium leading-6">
          No more view types can be added to this page!
        </h3>
      </div>
    );
  }

  return (
    <>
      <div className="align-center mb-5 flex flex-row items-center ">
        <h3 className="text-primary mr-3 text-lg font-medium leading-6">Select View Type</h3>
      </div>
      <div>
        <Select
          className="my-4"
          label="View Type"
          options={finalViewOptions}
          value={
            selectedView
              ? {
                  value: selectedView || "",
                  title: selectedView || ""
                }
              : null
          }
          onChange={(opt: SelectValue) => {
            setSelectedView((opt as SelectOption)?.value || "");
          }}
        />
      </div>
      <div className="flex w-full flex-row items-center justify-center">
        <Button
          label="Save"
          onClick={() => {
            if (!selectedView) return;
            handlePageViewSave(selectedView);
          }}
          appearance={ButtonAppearance.PRIMARY}
          disabled={!selectedView}
        />
      </div>
    </>
  );
};

export default AddNewViewToPage;
