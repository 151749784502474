"use client";

import React, { createContext, useState, useCallback, useRef } from "react";
import { useRouter } from "next/navigation";
import { Id } from "react-toastify";
import omit from "lodash/omit";
import dynamic from "next/dynamic";

import Modal from "components/Modal";

import useAddRecord from "hooks/useAddRecord";
import useUpsertDeleteFilters from "hooks/useUpsertDeleteFilters";
import useUpsertDeletePageViewConfig from "hooks/useUpsertDeletePageViewConfig";
import usePageDataById from "hooks/usePageDataById";

import { APP_FILTER_TYPES, ViewOption } from "utils/constants";
import toast from "utils/toast";

import type { Page, TableFilterType } from "types/baTypes";
import { CONFIG_MODAL_TYPES, RecordItem } from "types/common";
import { ApiColumnSection, ApiPageColumn, ApiPageViewColumn, ApiPageViewTabs, PIN_DIRECTION } from "types/apiTypes";

import { getSectionApiFieldsFromViewSections } from "utils/apiUtils";
import AddNewViewToPage from "./AddNewViewToPage";
import RecordType from "./RecordType";
import AddEditCustomFormView from "./AddEditCustomFormView";

const BasicPageSettings = dynamic(() => import("components/Admin/BasicPageSettings"), { ssr: false });

export interface PageConfigContextState {
  updatePageConfigModalState: (newState: ConfigModalState | null) => void;
}

export type ConfigModalState = {
  isOpen: boolean;
  type: CONFIG_MODAL_TYPES;
  additionalConfig?: RecordItem;
  onSuccess?: () => void;
};

export const PageConfigContext = createContext<PageConfigContextState | null>(null);

const { Provider } = PageConfigContext;

export const PageConfigContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const toastRef = useRef<Id>();
  const { addRecordAsync } = useAddRecord();
  const { handleAddNewPageView } = useUpsertDeletePageViewConfig();
  const { handleFilterChange } = useUpsertDeleteFilters();

  const [modalState, setModalState] = useState<ConfigModalState | null>(null);

  const { data: fullPageData } = usePageDataById(modalState?.additionalConfig?.parentPage?.id, {
    enabled: !!modalState?.additionalConfig?.parentPage?.id,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  const updatePageConfigModalState = useCallback((newState: ConfigModalState | null) => {
    if (!newState) {
      setModalState(null);
      return;
    }
    setModalState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  const createChildPage = useCallback(
    async ({ title, user_type, path, head_title }: Partial<Page>) => {
      toastRef.current = toast.success("Creating child page...");
      if (
        !modalState?.additionalConfig?.parentPage ||
        !fullPageData?.id ||
        fullPageData?.id !== modalState?.additionalConfig?.parentPage?.id
      )
        return;
      const parentPage: Page = modalState?.additionalConfig?.parentPage;
      const rest = omit(parentPage, [
        "id",
        "created_by",
        "updated_by",
        "ui_pages_actions",
        "childPages",
        "columns",
        "views",
        "tabs",
        "conditionalFormatting",
        "parentPage",
        "pageFilters",
        "algoliaSearchTableId",
        "isDefaultTablePage"
      ]);
      const addlInput = {
        ...rest,
        title,
        user_type,
        path: path?.startsWith("/") ? path : `/${path}`,
        head_title,
        parent_id: parentPage.id
      };
      const pageResp = await addRecordAsync({ tableName: "pages", input: addlInput });
      if (pageResp.data?.length) {
        const pageId = pageResp.data[0].id;

        // create pageFilters
        if (parentPage?.pageFilters?.filters?.length) {
          const filters = parentPage?.pageFilters?.filters?.map((filter) => omit(filter, ["id", "originalRuleItem"]));
          await handleFilterChange(filters, {
            pageId: pageId,
            filterType: APP_FILTER_TYPES.PAGE_FILTER
          });
        }

        // Create ui_page_views entries
        const pageViewsInputs: Array<{ views_id: string; pages_id: string }> = [];
        fullPageData.views?.forEach((view) => {
          if (view.viewId) {
            pageViewsInputs.push({
              views_id: view.viewId,
              pages_id: pageId
            });
          }
        });
        toast.update(toastRef.current, "Linking parent views to child page..");
        const pageViewsResponse = await addRecordAsync({ tableName: "ui_pages_views", input: pageViewsInputs });
        if (pageViewsResponse?.data?.length) {
          // Create ui_pages_views_columns entries
          const pageViewColumnsInputs: Partial<ApiPageViewColumn>[] = [];
          const pageColumnsInputs: Partial<ApiPageColumn>[] = [];
          const viewSectionsInput: Partial<ApiColumnSection>[] = [];
          const pageViewsData = pageViewsResponse.data;

          const pageViewTabInputs: Partial<ApiPageViewTabs>[] = [];
          const finalRequestsArr: RecordItem[] = [];

          // Create ui_pages_views_tabs entries
          fullPageData?.views?.forEach((pageView) => {
            if (pageView.viewId) {
              pageView.tabs?.forEach((pageViewTab) => {
                pageViewTabInputs.push({
                  ui_tab_id: pageViewTab.tabId,
                  page_view_id: pageViewsData.find(
                    (createdPageViewData: RecordItem) => createdPageViewData.views_id === pageView?.viewId
                  )?.id
                });
              });
            }
          });
          if (pageViewTabInputs.length) {
            toast.update(toastRef.current, "Linking pageView tabs to child pageView..");
            finalRequestsArr.push(addRecordAsync({ tableName: "ui_pages_views_tabs", input: pageViewTabInputs }));
          }

          if (!fullPageData?.columns?.length) return;
          for (const column of fullPageData.columns) {
            let lookupFilterId = null;
            let columnFilterId = null;
            // Duplicate column filters (lookup / column options)
            if (column.isLookup && !!column.lookupFilters?.length) {
              const lookFilter = column.lookupFilters[0];
              if (lookFilter) {
                const filterInput: TableFilterType = {
                  filterField: lookFilter.filterField,
                  filterOperator: lookFilter.filterOperator,
                  filterLookupPath: lookFilter.filterLookupPath,
                  filterValue: lookFilter.filterValue,
                  recordIdSource: lookFilter.recordIdSource
                };
                lookupFilterId = await handleFilterChange([filterInput], {
                  filterType: APP_FILTER_TYPES.LOOKUP_PATH
                });
              }
            }
            if (!!column.columnFilters?.length) {
              const colFilter = column.columnFilters[0];
              if (colFilter) {
                const filterInput: TableFilterType = {
                  filterField: colFilter.filterField,
                  filterOperator: colFilter.filterOperator,
                  filterLookupPath: colFilter.filterLookupPath,
                  filterValue: colFilter.filterValue,
                  recordIdSource: colFilter.recordIdSource
                };
                columnFilterId = await handleFilterChange([filterInput], {
                  filterType: APP_FILTER_TYPES.COLUMN_OPTIONS
                });
              }
            }
            pageColumnsInputs.push({
              page_id: pageId,
              columns_id: column.id,
              column_options_filter_id: columnFilterId,
              lookup_filter_id: lookupFilterId,
              is_admin_column: column.isAdminColumn,
              is_child: true
            });
            Object.keys(column.views || {}).forEach((viewType) => {
              const pageViewId = pageViewsData.find(
                (pageView: RecordItem) => pageView.views_id === column.views?.[viewType]?.view?.id
              )?.id;
              if (pageViewId) {
                pageViewColumnsInputs.push({
                  pages_views_id: pageViewId,
                  columns_id: column.id,
                  is_required: column.views?.[viewType]?.isRequired,
                  is_hidden: column.views?.[viewType]?.isHidden,
                  form_relation_page_id: column.views?.[viewType]?.formRelationPageId,
                  is_featured_image: column.views?.[viewType]?.isFeaturedImage,
                  is_group_by: column.views?.[viewType]?.isGroupBy,
                  is_group_by_second: column.views?.[viewType]?.isGroupBySecond,
                  sort: column.views?.[viewType]?.sort,
                  pin_direction: column.views?.[viewType]?.pinDirection as PIN_DIRECTION
                });
                const colView = column.views?.[viewType];
                // if sections are present create sections
                if (colView?.columnSections?.length && colView.view?.id) {
                  colView.columnSections.forEach((colSection) => {
                    if (
                      !viewSectionsInput.some(
                        (viewSection) =>
                          viewSection.title === colSection.title && viewSection.page_view_id === pageViewId
                      )
                    ) {
                      const sectionInput = getSectionApiFieldsFromViewSections(
                        omit({ ...colSection, pageViewId }, ["id", "columns"])
                      );
                      viewSectionsInput.push(sectionInput as ApiColumnSection);
                    }
                  });
                }
              }
            });
          }

          if (pageColumnsInputs.length) {
            toast.update(toastRef.current, "Linking parent columns to child page..");
            await Promise.all(
              pageColumnsInputs.map((input) => addRecordAsync({ tableName: "ui_pages_columns", input }))
            );
          }
          if (pageViewColumnsInputs.length) {
            if (viewSectionsInput.length) {
              toast.update(toastRef.current, "Linking child columns to child views..");
              const pageViewColumnsResponse = await addRecordAsync({
                tableName: "ui_pages_views_columns",
                input: pageViewColumnsInputs
              });

              if (pageViewColumnsResponse?.data?.length) {
                toast.update(toastRef.current, "Creating views sections...");
                const viewSectionsResponse = await Promise.all(
                  viewSectionsInput.map((sectionInput) =>
                    addRecordAsync({
                      tableName: "ui_column_sections",
                      input: sectionInput
                    })
                  )
                );
                const viewSectionsResponseData = viewSectionsResponse.map((resp) => resp.data?.[0]).filter(Boolean);
                if (viewSectionsResponseData?.length) {
                  const pageViewColSectionInputs: Array<{
                    ui_column_sections_id: string;
                    ui_pages_views_columns_id: string;
                  }> = [];

                  // add ui_pages_views_column_sections entries
                  for (const column of fullPageData.columns) {
                    Object.values(column.views || {})?.forEach((viewColumn) => {
                      // if sections are present create sections
                      if (viewColumn?.columnSections?.length && viewColumn.view?.id) {
                        viewColumn.columnSections.forEach((colSection) => {
                          const pageViewId = pageViewsData.find(
                            (pageView: RecordItem) => pageView.views_id === viewColumn.view?.id
                          )?.id;

                          const createdViewSection = viewSectionsResponseData.find(
                            (viewSection: ApiColumnSection) =>
                              viewSection.title === colSection.title && viewSection.page_view_id === pageViewId
                          );

                          const createPageViewCol = pageViewColumnsResponse.data.find(
                            (pageViewCol: ApiPageViewColumn) =>
                              pageViewCol.columns_id === column.id && pageViewCol.pages_views_id === pageViewId
                          );

                          if (createdViewSection && createPageViewCol) {
                            pageViewColSectionInputs.push({
                              ui_column_sections_id: createdViewSection.id,
                              ui_pages_views_columns_id: createPageViewCol.id
                            });
                          }
                        });
                      }
                    });
                  }

                  if (pageViewColSectionInputs.length) {
                    toast.update(toastRef.current, "Linking child view columns to sections..");
                    finalRequestsArr.push(
                      addRecordAsync({ tableName: "ui_pages_views_column_sections", input: pageViewColSectionInputs })
                    );
                  }
                }
              }
            } else {
              toast.update(toastRef.current, "Linking child columns to child views..");
              finalRequestsArr.push(
                addRecordAsync({ tableName: "ui_pages_views_columns", input: pageViewColumnsInputs })
              );
            }
          }
          if (!finalRequestsArr.length) return;
          const finalResponse = await Promise.all(finalRequestsArr);
          const errors = finalResponse.filter((resp) => !!resp.error);
          if (errors?.length) {
            toast.update(toastRef.current, "Child page creation failed!");
          } else {
            updatePageConfigModalState(null);
            toast.update(toastRef.current, "Child page successfully created!");
            router.push(`/table/${path?.replace("/", "")}/grid`);
          }
        }
      }
    },
    [
      addRecordAsync,
      modalState?.additionalConfig?.parentPage,
      fullPageData,
      handleFilterChange,
      router,
      updatePageConfigModalState
    ]
  );

  const saveNewPage = useCallback(
    async ({ title, user_type, path, head_title, table_name }: Partial<Page>) => {
      if (modalState?.additionalConfig?.isChildPage) {
        createChildPage({ title, user_type, path, head_title });
        return;
      }
      const input = {
        title,
        user_type,
        path: path?.startsWith("/") ? path : `/${path}`,
        head_title,
        table_name,
        page_config: {
          bar_controls_enabled: true
        }
      };
      const pageResp = await addRecordAsync({ tableName: "pages", input });
      if (pageResp.data?.length) {
        // Add grid view to page
        const pageId = pageResp.data[0].id;
        const viewInput = {
          viewType: ViewOption.GRID,
          pageId,
          onSuccess: () => {
            router.push(`/table/${path?.replace("/", "")}/grid`);
          }
        };
        updatePageConfigModalState(null);
        await handleAddNewPageView(viewInput);
      }
    },
    [
      addRecordAsync,
      handleAddNewPageView,
      router,
      updatePageConfigModalState,
      createChildPage,
      modalState?.additionalConfig?.isChildPage
    ]
  );

  return (
    <Provider value={{ updatePageConfigModalState }}>
      <Modal isOpen={!!modalState?.isOpen} onClose={() => updatePageConfigModalState(null)} classNameBg="p-5">
        {modalState?.type === CONFIG_MODAL_TYPES.BASIC_PAGE ? (
          <BasicPageSettings
            handlePageDataSave={saveNewPage}
            pageState={modalState?.additionalConfig?.parentPage}
            isChildPage={modalState?.additionalConfig?.isChildPage}
            isNew
          />
        ) : null}
        {modalState?.type === CONFIG_MODAL_TYPES.PAGE_VIEW &&
        modalState.additionalConfig?.pageId &&
        modalState.onSuccess ? (
          <AddNewViewToPage pageId={modalState.additionalConfig.pageId} onSuccess={modalState.onSuccess} />
        ) : null}
        {modalState?.type === CONFIG_MODAL_TYPES.FORM_VIEW && modalState.onSuccess ? (
          <AddEditCustomFormView
            isDefaultForm={modalState?.additionalConfig?.isDefaultForm}
            isPageView={modalState?.additionalConfig?.isPageView}
            onSuccess={modalState.onSuccess}
            initialViewConfig={modalState?.additionalConfig?.initialViewConfig}
            duplicateView={modalState?.additionalConfig?.duplicateView}
          />
        ) : null}
        {modalState?.type === CONFIG_MODAL_TYPES.RECORD_TYPE &&
        modalState.additionalConfig?.pageId &&
        modalState.onSuccess ? (
          <RecordType
            tableName={modalState.additionalConfig.tableName}
            pageId={modalState.additionalConfig.pageId}
            onSuccess={modalState.onSuccess}
          />
        ) : null}
      </Modal>
      {children}
    </Provider>
  );
};
