"use client";

import { useCallback, useEffect, useMemo, useState } from "react";

import Select from "components/Select";
import Button from "components/Button";

import useRecordTypes from "hooks/useRecordTypes";
import usePageConfigState from "hooks/usePageConfigState";
import useUpdateRecord from "hooks/useUpdateRecord";

import { RecordItem, SelectOption, SelectValue } from "types/common";
import { ButtonAppearance } from "utils/constants";
import usePageDataById from "hooks/usePageDataById";
import useSchemaState from "hooks/useSchemaState";

const colConfigOptions = [
  {
    value: "basic",
    title: "Basic"
  },
  {
    value: "lookup",
    title: "Lookup"
  }
];

const RecordType = ({
  pageId,
  onSuccess,
  tableName
}: {
  pageId: string;
  onSuccess: () => void;
  tableName?: string;
}) => {
  const { data: recordTypesData, refetch } = useRecordTypes();
  const { updatePageConfigModalState } = usePageConfigState();
  const { schema } = useSchemaState();
  const { data: pageData } = usePageDataById(pageId);
  const { updateRecordAsync } = useUpdateRecord();

  const [recordSchemaState, setRecordSchemaState] = useState<RecordItem>({});
  const [selectedType, setSelectedType] = useState<SelectOption>();
  const [recordSchemaType, setRecordSchemaType] = useState<"basic" | "lookup">();
  const [imageColType, setImageColType] = useState<"basic" | "lookup">();
  const [recordLookupCol, setRecordLookupCol] = useState<string | null>(null);
  const [imageLookupCol, setImageLookupCol] = useState<string | null>(null);

  // Image column config needs a `path` property in the schema column for the record type
  const hasImageColumn = useMemo(() => {
    if (!selectedType) return false;
    const { schema } = selectedType.recordType;
    const schemaKeys = Object.keys(schema || {});
    return schemaKeys?.length && schemaKeys?.some((key) => key === "path");
  }, [selectedType]);

  const recordTypeOptions = useMemo(() => {
    if (!recordTypesData) return [];
    return recordTypesData.map((recordType) => ({
      value: recordType.id,
      title: `${recordType.type}${
        recordType.page_id ? ` Assigned to (${recordType.page_id}/${recordType.tablename})` : ""
      }`,
      hasPageId: !!recordType.page_id,
      recordType
    }));
  }, [recordTypesData]);

  const pageColumns = useMemo(() => {
    return pageData?.columns
      ?.map((column) => (column.isLookup ? { title: column.header, value: column.id } : null))
      .filter(Boolean);
  }, [pageData]);

  const pageBasicColumns = useMemo(() => {
    if (!tableName || !schema) return [];
    const tableProps = schema[tableName]?.properties;
    return Object.keys(tableProps || {}).map((columnName) => ({
      title: columnName,
      value: columnName,
      col: tableProps[columnName]
    }));
  }, [tableName, schema]);

  const handleRecordTypeSave = useCallback(async () => {
    if (!selectedType) return;
    const input = {
      id: selectedType?.value,
      page_id: pageId,
      tablename: tableName,
      config: Object.keys(recordSchemaState)?.length ? recordSchemaState : null,
      lookup_column: recordLookupCol || null,
      image_column: imageLookupCol || null
    };
    await updateRecordAsync({ tableName: "record_types", input });
    updatePageConfigModalState(null);
    refetch();
  }, [
    pageId,
    selectedType,
    recordSchemaState,
    recordLookupCol,
    tableName,
    updateRecordAsync,
    updatePageConfigModalState,
    refetch,
    imageLookupCol
  ]);

  useEffect(() => {
    if (!selectedType) return;
    const { config, lookup_column, image_column, page_id } = selectedType.recordType;
    if (page_id !== pageId) return;
    if (lookup_column?.id) {
      setRecordSchemaType("lookup");
      const lookCol = pageColumns?.find((column) => column?.value === lookup_column.id);
      if (lookCol) setRecordLookupCol(lookCol?.value);
    }
    if (image_column?.id) {
      setImageColType("lookup");
      const lookCol = pageColumns?.find((column) => column?.value === image_column.id);
      if (lookCol) setImageLookupCol(lookCol?.value);
    }
    if (Object.keys(config || {}).length) {
      setRecordSchemaType("basic");
      setRecordSchemaState(config);
    }
  }, [selectedType, pageColumns, pageId]);

  return (
    <>
      <div className="align-center mb-5 flex flex-row items-center ">
        <h3 className="text-primary mr-3 text-lg font-medium leading-6">Select View Type</h3>
      </div>
      <div>
        <Select
          className="my-4"
          label="Record Type"
          options={recordTypeOptions}
          value={selectedType ? recordTypeOptions.find((recOpts) => recOpts?.value === selectedType?.value) : null}
          onChange={(opt: SelectValue) => {
            setSelectedType(opt as SelectOption);
          }}
        />
      </div>
      {selectedType?.title ? (
        <>
          <div>
            {" "}
            {hasImageColumn ? (
              <>
                <div>
                  {" "}
                  <Select
                    className="my-4"
                    label="Select Column Type for Image column (choose basic columns or lookup column)"
                    options={colConfigOptions}
                    value={imageColType ? { value: imageColType, title: imageColType } : null}
                    onChange={(opt: SelectValue) => {
                      setImageColType((opt as SelectOption)?.value as "basic" | "lookup");
                    }}
                  />
                </div>
                <>
                  {imageColType === "lookup" && selectedType ? (
                    <div className="w-full">
                      <Select
                        className="my-4"
                        label="Select Lookup Image column from page"
                        options={pageColumns as SelectOption[]}
                        value={imageLookupCol ? pageColumns?.find((column) => column?.value === imageLookupCol) : null}
                        onChange={(opt: SelectValue) => {
                          setImageLookupCol((opt as SelectOption)?.value);
                        }}
                      />
                    </div>
                  ) : null}
                  {imageColType === "basic" && selectedType ? (
                    <div className="w-full">
                      <div>
                        <Select
                          className="my-4"
                          label="Image path column"
                          options={pageBasicColumns}
                          value={
                            recordSchemaState["path"]
                              ? pageColumns?.find((column) => column?.value === recordSchemaState["path"])
                              : null
                          }
                          onChange={(opt: SelectValue) => {
                            setRecordSchemaState((prevState) => ({
                              ...prevState,
                              ["path"]: (opt as SelectOption)?.value
                            }));
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              </>
            ) : null}
            <Select
              className="my-4"
              label="Select Column Type for record config (choose basic columns or lookup column)"
              options={colConfigOptions}
              value={recordSchemaType ? { value: recordSchemaType, title: recordSchemaType } : null}
              onChange={(opt: SelectValue) => {
                setRecordSchemaType((opt as SelectOption)?.value as "basic" | "lookup");
              }}
            />
            <>
              {recordSchemaType === "lookup" && selectedType ? (
                <div className="w-full">
                  <Select
                    className="my-4"
                    label="Select Lookup column from page"
                    options={pageColumns as SelectOption[]}
                    value={recordLookupCol ? pageColumns?.find((column) => column?.value === recordLookupCol) : null}
                    onChange={(opt: SelectValue) => {
                      setRecordLookupCol((opt as SelectOption)?.value);
                    }}
                  />
                </div>
              ) : null}
              {recordSchemaType === "basic" && selectedType ? (
                <div className="w-full">
                  {Object.keys(selectedType.recordType?.schema || {}).map((schemaKey: string) => {
                    if (schemaKey === "path") return null;
                    const prop = selectedType.recordType?.schema[schemaKey];
                    if (prop?.type === "text") {
                      return (
                        <div key={schemaKey}>
                          <Select
                            className="my-4"
                            label={schemaKey}
                            options={pageBasicColumns}
                            value={
                              recordSchemaState[schemaKey]
                                ? pageColumns?.find((column) => column?.value === recordSchemaState[schemaKey])
                                : null
                            }
                            onChange={(opt: SelectValue) => {
                              setRecordSchemaState((prevState) => ({
                                ...prevState,
                                [schemaKey]: (opt as SelectOption)?.value
                              }));
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              ) : null}
            </>
          </div>
        </>
      ) : null}

      <div className="flex w-full flex-row items-center justify-center">
        <Button
          label="Save"
          onClick={() => {
            handleRecordTypeSave();
          }}
          appearance={ButtonAppearance.PRIMARY}
          disabled={!selectedType || !(Object.keys(recordSchemaState)?.length || recordLookupCol)}
        />
      </div>
    </>
  );
};

export default RecordType;
