"use client";

import { useQueryClient } from "@tanstack/react-query";
import { Plus as PlusIcon } from "lucide-react";
import { useRouter, useSelectedLayoutSegments } from "next/navigation";
import { useCallback, useEffect, useState } from "react";

import useAllPagesForAdmin from "hooks/useAllPagesForAdmin";
import usePageConfigState from "hooks/usePageConfigState";
import useUpsertDeletePageViewConfig, { DuplicatePageViewInput } from "hooks/useUpsertDeletePageViewConfig";
import useUpsertDeleteView from "hooks/useUpsertDeleteView";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Loader from "components/Loader";
import Select from "components/Select";

import { ButtonAppearance, ViewOption } from "utils/constants";

import FormConfigUI from "components/Admin/AdminViewConfig/FormConfigUI";
import TextField from "components/TextField";
import { ComboBox } from "components/ui/ComboBox";
import { ApiFormViewAdditionalConfig } from "types/apiTypes";
import { Page, TableColumnType, TableViewConfig } from "types/baTypes";
import { SelectOption } from "types/common";

const AddEditCustomFormView = ({
  onSuccess,
  isPageView,
  initialViewConfig,
  duplicateView, // used to duplicate pageView
  isDefaultForm = false
}: {
  onSuccess: () => void;
  isPageView?: boolean;
  initialViewConfig?: TableViewConfig;
  duplicateView?: boolean;
  isDefaultForm?: boolean;
}) => {
  const [formViewState, setFormViewState] = useState<TableViewConfig>();
  const [currentSelectedPage, setCurrentSelectedPage] = useState<Page | undefined>();
  const [selectedPages, setSelectedPages] = useState<Page[]>([]);
  const [formColumns, setFormColumns] = useState<{ [key: string]: TableColumnType[] }>({});
  const [viewTitle, setViewTitle] = useState("");
  const [viewSubType, setViewSubType] = useState("");
  const [pageData, setPageData] = useState<Page | undefined>();

  const layoutSegments = useSelectedLayoutSegments();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { handleAddNewView } = useUpsertDeleteView();
  const { data: allPagesData, isLoading } = useAllPagesForAdmin();
  const { updatePageConfigModalState } = usePageConfigState();
  const { handleAddNewPageView, handleSaveView, handleDuplicatePageView } = useUpsertDeletePageViewConfig();
  const allPageOptions = allPagesData?.map((page) => ({
    title: `${page.title} (${page.path} - ${page.user_type})`,
    value: page.id,
    page
  }));
  const hasDifferentTables = !!selectedPages.length && selectedPages.map((page) => page.table_name)?.length > 1;
  const isStaticCustomForm = formViewState?.additionalConfig?.isStaticCustomForm;
  const handleAddNewPage = useCallback(() => {
    if (currentSelectedPage) {
      setSelectedPages((prev) => [...prev, currentSelectedPage]);
      setCurrentSelectedPage(undefined);
    }
  }, [currentSelectedPage]);

  const handleUpdateFormViewState = useCallback(
    async ({
      newViewConfig,
      newAdditionalConfig
    }: {
      newViewConfig?: Partial<TableViewConfig>;
      newAdditionalConfig?: ApiFormViewAdditionalConfig;
    }) => {
      setFormViewState((prev) => {
        return {
          ...(prev || {}),
          ...newViewConfig,
          additionalConfig: {
            ...(prev?.additionalConfig || {}),
            ...newAdditionalConfig
          }
        } as TableViewConfig;
      });
    },
    []
  );

  const handleFormViewSuccess = useCallback(() => {
    updatePageConfigModalState(null);
    onSuccess?.();
    queryClient.invalidateQueries({ queryKey: ["table", "ui_views"] });
    queryClient.refetchQueries({ queryKey: ["table", "ui_views"] });
  }, [updatePageConfigModalState, onSuccess, queryClient]);

  const onSaveView = useCallback(async () => {
    const finalViewInput = {
      viewType: ViewOption.FORM,
      isCustom: isDefaultForm ? false : true,
      subType: isDefaultForm ? "create" : "custom",
      isDefault: !!isDefaultForm,
      ...formViewState
    };
    const finalColumnInputs = isStaticCustomForm
      ? []
      : Object.values(formColumns)
          .flat()
          .map((col) => ({
            columnId: col.id,
            pageColumnId: col.pageColumnId
          }));
    await handleAddNewView({
      newViewConfig: finalViewInput,
      columnIds: finalColumnInputs,
      onSuccess: handleFormViewSuccess
    });
  }, [formViewState, formColumns, handleFormViewSuccess, handleAddNewView, isDefaultForm, isStaticCustomForm]);

  const onSavePageView = useCallback(async () => {
    if (!pageData) return;

    // create new View
    if (!initialViewConfig) {
      const input = {
        viewType: ViewOption.FORM,
        pageId: pageData.id,
        additionalInput: {
          title: viewTitle,
          sub_type: viewSubType,
          is_default: !!isDefaultForm
        },
        additionalPageViewInput: {
          is_custom: isDefaultForm ? false : true
        },
        onSuccess: (pageViewId?: string) => {
          router.refresh();
          onSuccess?.();
          router.push(`/admin/tables/${pageData.id}/form/${pageViewId}`);
        }
      };
      await handleAddNewPageView(input);
      updatePageConfigModalState(null);
    } else {
      if (duplicateView) {
        // duplicate the existing view
        const input: DuplicatePageViewInput = {
          pageId: pageData.id,
          fullPageView: initialViewConfig,
          additionalViewConfigInput: {
            title: viewTitle,
            subType: viewSubType
          },
          additionalPageViewConfigInput: {},
          onSuccess: (pageViewId?: string) => {
            router.refresh();
            onSuccess?.();
            router.push(`/admin/tables/${pageData.id}/form/${pageViewId}`);
          }
        };
        await handleDuplicatePageView(input);
      } else {
        // update the existing view
        await handleSaveView({
          newViewConfig: {
            title: viewTitle,
            subType: viewSubType
          },
          newAdditionalConfig: {},
          fullPageView: initialViewConfig,
          onSuccess: () => {
            router.refresh();
            onSuccess?.();
          }
        });
      }
      updatePageConfigModalState(null);
    }
  }, [
    handleAddNewPageView,
    pageData,
    viewSubType,
    viewTitle,
    router,
    updatePageConfigModalState,
    onSuccess,
    initialViewConfig,
    handleSaveView,
    duplicateView,
    handleDuplicatePageView,
    isDefaultForm
  ]);

  // only support edit for pageView custom forms
  useEffect(() => {
    if (!initialViewConfig || !isPageView) return;

    setFormViewState((prev) => ({
      ...prev,
      ...initialViewConfig,
      additionalConfig: {
        ...prev?.additionalConfig,
        ...initialViewConfig.additionalConfig
      }
    }));
    const finalTitle = initialViewConfig.title
      ? duplicateView
        ? `${initialViewConfig.title} (Copy)`
        : initialViewConfig.title
      : "";
    const finalSubType = initialViewConfig.subType
      ? duplicateView
        ? `${initialViewConfig.subType} (Copy)`
        : initialViewConfig.subType
      : "";
    setViewTitle(finalTitle);
    setViewSubType(finalSubType);
  }, [initialViewConfig, isPageView, duplicateView]);

  useEffect(() => {
    if (hasDifferentTables && !formViewState?.additionalConfig?.byPassDbInsert) {
      setFormViewState(
        (prev) =>
          ({
            ...prev,
            additionalConfig: {
              ...prev?.additionalConfig,
              byPassDbInsert: true,
              formSubmitWebhook: {
                url: ""
              }
            }
          }) as TableViewConfig
      );
    }
  }, [hasDifferentTables, formViewState?.additionalConfig?.byPassDbInsert]);

  useEffect(() => {
    // For now only available in admin route
    if (!isPageView || layoutSegments?.[0] !== "admin") {
      if (pageData) {
        setPageData(undefined);
      }
      return;
    }
    const pageId = layoutSegments?.[2];
    if (pageId !== `${pageData?.id}`) {
      const page = allPagesData?.find((p) => `${p.id}` === pageId);
      setPageData(page);
    }
  }, [isPageView, layoutSegments, pageData, allPagesData]);

  if (isPageView && isLoading) {
    return <Loader />;
  }

  if (isPageView) {
    return (
      <div className="flex flex-col">
        <div className="text-md">Custom Form View for {pageData?.path}</div>
        <div className="my-4">
          <TextField
            label="View Title"
            value={viewTitle}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
              setViewTitle(value);
            }}
            onBlur={() => {
              handleUpdateFormViewState({
                newAdditionalConfig: {},
                newViewConfig: {
                  title: viewTitle
                }
              });
            }}
            required
            className="max-w-[350px]"
          />
        </div>
        <div className="my-4">
          <TextField
            label="View Sub Type (Tag)"
            value={viewSubType}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
              setViewSubType(value);
            }}
            onBlur={() => {
              handleUpdateFormViewState({
                newAdditionalConfig: {},
                newViewConfig: {
                  subType: viewSubType
                }
              });
            }}
            required
            className="max-w-[350px]"
          />
        </div>
        <div className="my-5 flex flex-row items-center justify-center">
          <Button
            label="Save Form View"
            appearance={ButtonAppearance.PRIMARY}
            onClick={onSavePageView}
            disabled={!viewSubType || !viewTitle}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="text-md">{isStaticCustomForm ? "Custom Static Form View" : "Custom Form View"}</div>
      <div className="my-4">
        <TextField
          label="View Title"
          value={viewTitle}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setViewTitle(value);
          }}
          onBlur={() => {
            handleUpdateFormViewState({
              newAdditionalConfig: {},
              newViewConfig: {
                title: viewTitle
              }
            });
          }}
          required
          className="max-w-[350px]"
        />
      </div>
      {!isStaticCustomForm && (
        <>
          <div className="flex flex-row ">
            <div className="mr-2 flex-1 space-y-3">
              <div className="text-md">Select Page</div>
              <ComboBox
                defaultLabel="Select Page"
                options={allPageOptions || []}
                defaultValue={currentSelectedPage?.id}
                onSelect={(_, opt) => {
                  setCurrentSelectedPage(opt?.page);
                }}
              />
            </div>
            <IconButton icon={PlusIcon} onClick={handleAddNewPage} />
          </div>
          {selectedPages.map((page) => {
            return (
              <div key={page.id} className="border-1 my-5 flex flex-col border p-4">
                <div className="mt-3 text-sm">Page : {page.title}</div>
                <div className="flex flex-row justify-between">
                  <Select
                    className="mr-2 flex-1"
                    options={
                      page.columns?.map((col) => ({ title: col?.nickname || col?.header, value: col?.id, col: col })) ||
                      []
                    }
                    value={formColumns?.[page.id]?.map((col) => ({
                      title: col?.nickname || col?.header,
                      value: col?.id,
                      col: col
                    }))}
                    label="Select Columns"
                    onChange={(opt: SelectOption | SelectOption[] | null) => {
                      setFormColumns((prev) => {
                        return {
                          ...prev,
                          [page.id]: opt?.length ? opt?.map((o: SelectOption) => o?.col) : []
                        };
                      });
                    }}
                    multiple
                    showClear
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
      {!!selectedPages?.length || isStaticCustomForm ? (
        <div className="">
          <FormConfigUI
            viewConfig={formViewState}
            handleChange={handleUpdateFormViewState}
            isWebhookRequired={!!hasDifferentTables || isStaticCustomForm}
            isStaticCustomForm={isStaticCustomForm}
          />
        </div>
      ) : null}
      {!isPageView && !initialViewConfig && (
        <Button
          label={`Toggle${isStaticCustomForm ? "" : " Static"} Custom Form View`}
          className="my-4 w-fit"
          onClick={() =>
            handleUpdateFormViewState({
              newAdditionalConfig: {
                isStaticCustomForm: !isStaticCustomForm,
                formSubmitWebhook: isStaticCustomForm
                  ? undefined
                  : {
                      url: ""
                    },
                waitForSuccess: isStaticCustomForm ? false : true,
                byPassDbInsert: isStaticCustomForm ? false : true
              }
            })
          }
          appearance={ButtonAppearance.SECONDARY}
        />
      )}

      <div className="my-5 flex flex-row items-center justify-center">
        <Button
          label="Save View"
          appearance={ButtonAppearance.PRIMARY}
          onClick={onSaveView}
          disabled={
            isStaticCustomForm
              ? !formViewState?.additionalConfig?.formSubmitWebhook?.url
              : !selectedPages?.length ||
                !Object.values(formColumns || {}).flat().length ||
                (!!formViewState?.additionalConfig?.byPassDbInsert &&
                  !formViewState?.additionalConfig?.formSubmitWebhook?.url)
          }
        />
      </div>
    </div>
  );
};

export default AddEditCustomFormView;
