import { useContext } from "react";
import { PageConfigContext, PageConfigContextState } from "context/PageConfigContext";

const usePageConfigState = () => {
  const pageConfigState = useContext<PageConfigContextState | null>(PageConfigContext);

  if (typeof pageConfigState === "undefined" || typeof pageConfigState !== "object") {
    return {
      updatePageConfigModalState: () => {},
    };
  }

  return pageConfigState as PageConfigContextState;
};

export type PageConfigContextType = ReturnType<typeof usePageConfigState>;

export default usePageConfigState;
